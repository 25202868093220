<template>
	<b-container fluid>
		<b-row>
			<Breadcrumb titulo="USO TELEFÔNICO"
						:items="breadcrumb"
						class="breadcrumb" />
		</b-row>
		<b-row class="m-0">
			<b-col class="relatorio-container py-3 px-4">
				<b-row align-h="between">
					<b-col cols="12" md="auto">
						<input type="date" v-model="dataInicio" class="px-2 py-1 mr-3" />
						<input type="date" v-model="dataFim" class="px-2 py-1 mr-3" />
						<select v-if="setores" v-model="setor" class="px-2 mr-3">
							<option :value="null">Setor</option>
							<option v-for="setor in setores" :value="setor.Id">{{setor.Nome}}</option>
						</select>
						<select v-if="atendentes" v-model="atendente" class="px-2 mr-3">
							<option :value="null">Atendente</option>
							<option v-for="atendente in atendentes" :value="atendente.Id">{{atendente.Nome}}</option>
						</select>
						<div class="input-search px-2 py-1 mr-3">
							<i v-if="selectedCliente" class="fas fa-user text-info"></i>
							<input type="text"
								   ref="input-search"
								   placeholder="Cliente"
								   autocomplete="off"
								   v-model="clientesQuery"
								   :class="`mr-2${selectedCliente ? ' text-info font-weight-bold' : ''}`"
								   :readonly="selectedCliente != null" />
							<b-spinner v-if="searchClientesTimer || clientesRequest" small class="text-info"></b-spinner>
							<i v-else-if="clientes" class="fas fa-times fa-fw" @click="clearClientes"></i>
							<i v-else class="fas fa-search fa-fw" @click="$refs['input-search'].focus()"></i>
							<div class="search-results app-scroll-custom" v-if="clientes && !selectedCliente">
								<span v-if="clientes.length == 0" class="font-italic small px-2">
									Nenhum cliente encontrado.
								</span>
								<div v-for="cliente in clientes" :key="cliente.Id" @click="selectCliente(cliente)">
									<div class="text-nowrap font-weight-bold text-info">
										<i class="fas fa-user mr-1"></i>
										{{cliente.RazaoSocialNome}}
									</div>
									<div class="text-nowrap small">
										<div v-if="cliente.CnpjCpf">
											<b>CPF/CNPJ:</b>
											{{cliente.CnpjCpf}}
										</div>
										<div v-if="cliente.Telefone">
											<b>Telefone:</b>
											{{cliente.Telefone}}
										</div>
										<div>
											<b>Data de Cadastro:</b>
											{{cliente.Inclusao}}
										</div>
									</div>
								</div>
							</div>
						</div>
						<input type="number" v-model="protocolo" placeholder="Protocolo" class="px-2 mr-3" />
						<b-button size="sm" variant="info" class="rounded-0 px-4 py-1 box-cliente border-0" @click="searchRegistros">Filtrar</b-button>
					</b-col>
					<b-col cols="12" md="auto">
						<div class="data-field insider-icon border-0 mb-0">
							<label for="uso-telefonico-agrupamento">Agrupamento:</label>
							<select v-model="agrupamento" id="uso-telefonico-agrupamento" @change="searchRegistros">
								<option v-for="agrupamento in agrupamentos" :value="agrupamento.value">
									{{agrupamento.label}}
								</option>
							</select>
						</div>
					</b-col>
				</b-row>
				<b-row>
					<b-col class="relatorio-data-container app-scroll-custom m-3 px-0">
						<b-table v-if="registros || searchRegistrosIsLoading"
								 striped
								 hover
								 :fields="columns"
								 :items="registros"
								 no-select-on-click
								 class="mb-0">
							<template #cell(Informacoes)="scope">
								<i class="fas fa-info-circle fa-lg text-dark" @click.stop="showAtendimentoInfo(scope.item)"></i>
							</template>
							<template #cell(Protocolo)="scope">
								<b>{{scope.value}}</b>
							</template>
							<template #cell(Origem)="scope">
								<i :class="`fas fa-long-arrow-alt-up call-${scope.value} mr-2`"></i>
								{{scope.value == "incoming" ? "Receptivo" : "Ativo"}}
							</template>
						</b-table>
						<div v-if="searchRegistrosIsLoading" class="text-center font-weight-bold m-2">
							<b-spinner variant="secondary" small class="mr-1"></b-spinner>
							Processando solicitação...
						</div>
						<div v-else-if="registros?.length == 0" class="text-center m-2">
							Não existem registros de chamada no sistema com base nos filtros informados.
						</div>
					</b-col>
				</b-row>
			</b-col>
		</b-row>
		<ModalConversaPadrao ref="modal-informacoes-atendimento"
							 :dadosConversa="selectedAtendimentoDados" />
	</b-container>
</template>
<script>
	import moment from "moment";
	import Breadcrumb from "@/components/breadcrumb/Breadcrumb";
	import axios from "axios";
	import ModalConversaPadrao from '@/components/consultas/ModalCoversaPadrao';
	export default {
		name: "ConsultaUsoTelefonico",
		components: {
			Breadcrumb,
			ModalConversaPadrao
		},
		data() {
			return {
				breadcrumb: [
					{
						id: "1",
						classe: "fas fa-search",
						texto: "Consultas",
						link: "/consultas",
						cor: "#259cd3"
					},
					{
						id: "2",
						classe: "fa fa-phone flip-h",
						texto: "Uso Telefônico",
						link: "/uso-telefonico",
						cor: "#259cd3"
					}
				],
				fields: [],
				dataInicio: moment().format("YYYY-MM-DD"),
				dataFim: moment().format("YYYY-MM-DD"),
				setores: null,
				setor: null,
				atendentes: null,
				atendente: null,
				clientes: null,
				clientesQuery: "",
				searchClientesTimer: null,
				clientesRequest: null,
				selectedCliente: null,
				protocolo: null,
				agrupamento: "atendimento",
				agrupamentos: [
					{ value: "atendimento", label: "Atendimento" },
					{ value: "chamada", label: "Chamada" },
					{ value: "cliente", label: "Cliente" },
					{ value: "setor", label: "Setor" },
					{ value: "usuario", label: "Usuário" }
				],
				registros: null,
				searchRegistrosIsLoading: false,
				selectedAtendimento: null
			};
		},
		computed: {
			columns() {
				if (!this.registros || this.registros.length == 0) return [];
				let fields = Object.keys(this.registros[0]);
				let ignoreFields = "AtendimentoId,SetorId,UsuarioId,ClienteId".split(",");
				let fieldsLabels = {
					Usuario: "Usuário",
					Inicio: "Início",
					DuracaoAtiva: "Duração Ativa",
					DuracaoReceptiva: "Duração Receptiva",
					DuracaoTotal: "Duração Total",
					Quantidade: "Ligações"
				};
				if (fields.includes("Origem") && fields.indexOf("DuracaoAtiva") != -1) fields.splice(fields.indexOf("DuracaoAtiva"), 1);
				if (fields.includes("Origem") && fields.indexOf("DuracaoReceptiva") != -1) fields.splice(fields.indexOf("DuracaoReceptiva"), 1);
				return [...(fields.includes("Protocolo") ? ["Informacoes"] : []), ...fields.filter(item => !ignoreFields.includes(item)).map(item => ({ key: item, label: fieldsLabels[item] ?? item }))];
			},
			selectedAtendimentoDados() {
				if (!this.selectedAtendimento) return {};
				this.$refs["modal-informacoes-atendimento"].show();
				return {
					Id: this.selectedAtendimento.AtendimentoId,
					Protocolo: this.selectedAtendimento.Protocolo,
					ClienteId: this.selectedAtendimento.ClienteId
				};
			}
		},
		watch: {
			clientesQuery() {
				this.searchClientes();
			}
		},
		methods: {
			searchClientes() {
				if (this.searchClientesTimer) clearTimeout(this.searchClientesTimer);
				if (this.clientesRequest) this.clientesRequest.cancel();
				if (this.selectedCliente) return;
				if (!this.clientesQuery.trim()) {
					this.clientes = null;
					this.searchClientesTimer = null;
					this.clientesRequest = null;
					return;
				}
				this.searchClientesTimer = setTimeout(() => {
					this.clientesRequest = axios.CancelToken.source();
					axios.get(`/api/cliente/instant-search?query=${this.clientesQuery}`, {
						cancelToken: this.clientesRequest.token
					}).then(response => {
						this.clientes = response.data;
						this.searchClientesTimer = null;
						this.clientesRequest = null;
					}).catch(() => {
						this.clientes = null;
						this.searchClientesTimer = null;
						this.clientesRequest = null;
					});
				}, 300);
			},
			selectCliente(cliente) {
				this.selectedCliente = cliente;
				this.clientesQuery = cliente.RazaoSocialNome ?? cliente.CnpjCpf ?? cliente.Telefone;
			},
			clearClientes() {
				this.clientesQuery = "";
				this.clientes = null;
				this.selectedCliente = null;
			},
			searchRegistros() {
				this.registros = null;
				const form = {
					DataInicio: this.dataInicio,
					DataFim: this.dataFim,
					SetorId: this.setor,
					UsuarioId: this.atendente,
					ClienteId: this.selectedCliente?.Id,
					Protocolo: this.protocolo,
					Agrupamento: this.agrupamento
				};
				this.searchRegistrosIsLoading = true;
				axios.get("/api/consultas/uso-telefonia", {
					params: Object.assign({}, ...Object.keys(form).filter(key => form[key]).map(key => ({ [key]: form[key] })))
				}).then(response => {
					this.registros = response.data?.map(registro => {
						let fieldsFormat = {
							Inicio(value) {
								return moment(value).format("DD/MM/YYYY HH:mm:ss");
							},
							Fim(value) {
								return moment(value).format("DD/MM/YYYY HH:mm:ss");
							},
							DuracaoAtiva(value) {
								return moment().startOf("day").add(value).format("HH:mm:ss.S");
							},
							DuracaoReceptiva(value) {
								return moment().startOf("day").add(value).format("HH:mm:ss.S");
							},
							DuracaoTotal(value) {
								return moment().startOf("day").add(value).format("HH:mm:ss.S");
							}
						};
						Object.keys(registro).forEach(key => {
							registro[key] = (fieldsFormat[key] ?? (value => value))(registro[key]);
						});
						return registro;
					});
					this.searchRegistrosIsLoading = false;
				}).catch(() => {
					this.registros = null;
					this.searchRegistrosIsLoading = false;
				});
			},
			showAtendimentoInfo(atendimento) {
				this.selectedAtendimento = null;
				this.selectedAtendimento = atendimento;
			}
		},
		created() {
			axios.get(`api/setor/lista-basica`).then(response => {
				this.setores = response.data;
			});
			axios.get(`api/usuario/lista-basica`).then(response => {
				this.atendentes = response.data;
			});
			this.searchRegistros();
		}
	}
</script>
<style scoped>
	.breadcrumb {
		background-color: #FD7E14;
	}

	.relatorio-container {
		background-color: var(--cinza-3);
		min-width: 690px;
		height: calc(100vh - 140px);
		animation: ease-in slide-left .3s;
	}

	.relatorio-data-container {
		height: calc(100vh - 250px);
		background-color: #fff;
		font-size: 14px;
		color: var(--cinza-5);
		cursor: default;
		animation: ease-in slide-left .5s;
	}

		.relatorio-data-container table {
			color: var(--cinza-5);
			animation: ease-in slide-up .3s;
		}

	input, select, .input-search {
		display: inline-block;
		background-color: #fff;
		border: 0;
		outline-style: none;
		font-size: 14px;
		color: var(--cinza-5);
	}

	input {
		padding: 5px;
	}

	select {
		padding: 7px;
	}

	.input-search {
		position: relative;
	}

		.input-search > input[type=text] {
			width: 200px;
			padding: 1px;
		}

			.input-search > input[type=text]:focus {
				box-shadow: none;
			}

		.input-search > i.fa-user {
			font-size: 13px;
			margin-right: 5px;
		}

			.input-search > i.fa-user + input[type=text] {
				width: 184px;
			}

		.input-search > i:not(.fa-circle-notch) {
			cursor: pointer;
		}

	.search-results {
		position: absolute;
		min-width: 100%;
		max-height: 50vh;
		top: calc(100% + 3px);
		left: -1px;
		background-color: #fff;
		z-index: 1000;
		box-shadow: 2px 2px 2px #ccc;
		border: 1px var(--cinza-3) solid;
		overflow-x: hidden;
		animation: ease-in slide-down .3s;
	}

		.search-results > div {
			padding: 5px 10px;
			border-bottom: 1px var(--cinza-3) solid;
			cursor: pointer;
			transition: all ease-in-out .3s;
		}

			.search-results > div:hover {
				background-color: var(--cinza-1);
			}

	.fas.fa-info-circle {
		cursor: pointer;
	}

	.fas.call-incoming {
		color: #1cd534;
		transform: rotate(-135deg);
	}

	.fas.call-outgoing {
		color: #e43434;
		transform: rotate(45deg);
	}

	@keyframes slide-left {
		0% {
			opacity: 0;
			left: 30px;
		}

		70% {
			opacity: .7;
			left: 7px;
		}

		100% {
			opacity: 1;
			left: 0;
		}
	}

	@keyframes slide-down {
		0% {
			opacity: 0;
			margin-top: -20px;
		}

		70% {
			opacity: .7;
			margin-top: -7px;
		}

		100% {
			opacity: 1;
			margin-top: 0;
		}
	}

	@keyframes slide-up {
		0% {
			opacity: 0;
			margin-top: 20px;
		}

		70% {
			opacity: .7;
			margin-top: 7px;
		}

		100% {
			opacity: 1;
			margin-top: 0;
		}
	}
</style>